import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import { carColumns, CarData } from '../admin/PickedCars';
import axios from '../../config/server';
import Swal from 'sweetalert2';

const DriverDropedCars = () => {
    const [data, setData] = useState([]); // Manage the car data in state
    const [branches, setBranches] = useState([]); // Manage the car data in state

    // Fetch car data from backend on component mount
    const fetchCars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cars`);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching car data:', error);
        }
    };
    const fetchBranches = async () => {
        try {
            const branchesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(branchesResponse.data); // Fetch branches
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire('Error', 'Failed to fetch data.', 'error');
        }
    };
    useEffect(() => {
        fetchCars();
        fetchBranches()
    }, []); // Empty array ensures this runs once when the component is mounted

    const tableData = data?.filter(x => x?.status === "droped")?.map(x=>{
        const logo = x?.carBrandLogo?.data;
        const contentType = x?.carBrandLogo?.contentType;
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;
        return {
            name : x?.name,
            branch : x?.branch?.name,
            carBrandLogo: logoBase64,
            branchId : x?.branch?._id,
            driver : x?.driver?.name,
            customerMobile : x?.customerMobile,
            carBrand : x?.carBrand,
            licensePlate : x?.licensePlate,
            pickupTime : new Date(x?.pickupTime).toLocaleDateString() + " "+  new Date(x?.pickupTime).toLocaleTimeString(),
            _id : x?._id,
            status : x?.status,
            dropOffTime : x?.dropOffTime ? new Date(x?.dropOffTime)?.toLocaleDateString()  + " "+  new Date(x?.dropOffTime).toLocaleTimeString() : "-----"
        }
    })
    return (
        <div>
            <Layout active={'Cars'} sub_active={'Droped'} isAdmin={false}>
                <CardSection title={'Droped Cars'}>
                    <GenericTable data={tableData} columns={carColumns} />
                </CardSection>
            </Layout>
        </div>
    )
}

export default DriverDropedCars;