import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import { Eye, Edit, Trash } from 'lucide-react';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';
import { Form } from 'react-bootstrap';
import axios from 'axios'; // Import axios

const initialBranchData = [];

export const branchColumns = [
    { header: "Logo", accessor: "logo", type: "image" },
    { header: "Name", accessor: "name", type: "text" },
    { header: "Active", accessor: "isActive", type: "checkbox" },
    { header: "Address", accessor: "address", type: "text" },
];

const Branches = () => {
    const [branchData, setBranchData] = useState(initialBranchData);

    // Fetch branches on component mount
    const fetchBranches = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranchData(response.data); // Assuming the response returns an array of branches
        } catch (error) {
            Swal.fire('Error', 'Failed to load branches.', 'error');
        }
    };
    useEffect(() => {
        fetchBranches();
    }, []); // Empty dependency array ensures this runs once when the component mounts

    // Add Branch
    const addBranch = () => {
        Swal.fire({
            title: 'Add New Branch',
            html: ReactDOMServer.renderToStaticMarkup(
                <div>
                    <Form className="text-start">
                        <Form.Group className="mb-3">
                            <Form.Label className="blue-shade">Logo</Form.Label>
                            <Form.Control className="bg-white blue-shade" type="file" id="branchLogo" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="blue-shade">Name</Form.Label>
                            <Form.Control className="bg-white blue-shade" type="text" id="newName" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label className="blue-shade">Address</Form.Label>
                            <Form.Control className="bg-white blue-shade" type="text" id="newAddress" />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label="Active"
                                defaultChecked
                                id="newIsActive"
                            />
                        </Form.Group>
                    </Form>
                </div>
            ),
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Title color
                    confirmBTN.style.color = '#fff'; // Title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            showCancelButton: true,
            confirmButtonText: 'Add Branch',
            cancelButtonText: 'Cancel',
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            preConfirm: () => {
                const newBranch = {
                    logo: document.getElementById('branchLogo').files[0], // Get the file from the file input
                    name: document.getElementById('newName').value,
                    adress: document.getElementById('newAddress').value,
                    isActive: document.getElementById('newIsActive').checked,
                };

                const formData = new FormData();
                formData.append('logo', newBranch.logo);
                formData.append('name', newBranch.name);
                formData.append('description', newBranch.adress);
                formData.append('isActive', newBranch.isActive);

                // Send the form data to the backend API
                return axios.post(`${process.env.REACT_APP_API_URL}/branches`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then(response => {
                    return response.data.branch; // Return the newly added branch
                }).catch(error => {
                    Swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
        }).then((result) => {
            if (result.isConfirmed) {
                fetchBranches()
                Swal.fire('Added!', `${result.value.name} has been added to the list.`, 'success');
            }
        });
    };

    // Edit Branch
    const showEditBranchAlert = (row) => {
        const htmlContent = ReactDOMServer.renderToStaticMarkup(
            <div>
                <Form className="text-start">
                    <Form.Group className="mb-3">
                        <Form.Label className="blue-shade">Name</Form.Label>
                        <Form.Control className="bg-white blue-shade" type="text" defaultValue={row.name} id="editName" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label className="blue-shade">Address</Form.Label>
                        <Form.Control className="bg-white blue-shade" type="text" defaultValue={row.address} id="editAddress" />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Check type="checkbox" className="blue-shade" label="Active" defaultChecked={row.isActive} id="editIsActive" />
                    </Form.Group>
                </Form>
            </div>
        );

        Swal.fire({
            title: `Edit Branch: ${row.name}`,
            html: htmlContent,
            confirmButtonText: 'Save Changes',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Title color
                    confirmBTN.style.color = '#fff'; // Title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            preConfirm: () => {
                const updatedBranch = {
                    ...row,
                    name: document.getElementById('editName').value,
                    address: document.getElementById('editAddress').value,
                    isActive: document.getElementById('editIsActive').checked,
                };

                // Send the updated branch data to the backend API
                return axios.patch(`${process.env.REACT_APP_API_URL}/branches/${row.id}`, updatedBranch)
                    .then(response => response.data) // Return the updated branch data
                    .catch(error => {
                        Swal.showValidationMessage(`Request failed: ${error}`);
                    });
            },
        }).then((result) => {
            if (result.isConfirmed) {
                fetchBranches()
                Swal.fire('Updated!', `${result?.value?.branch?.name} has been updated.`, 'success');
            }
        });
    };

    // Delete Branch
    const showDeleteBranchAlert = (row) => {
        Swal.fire({
            title: `Are you sure you want to delete ${row.name}?`,
            icon: 'warning',
            confirmButtonText: 'Delete',
            confirmButtonColor: '#d33',
            cancelButtonText: 'Cancel',
            showCancelButton: true,
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Title color
                    confirmBTN.style.color = '#fff'; // Title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Call the delete API to remove the branch from the backend
                axios.delete(`${process.env.REACT_APP_API_URL}/branches/${row.id}`)
                    .then(() => {
                        setBranchData((prev) => prev.filter((branch) => branch?._id !== row.id));
                        Swal.fire('Deleted!', `${row.name} has been deleted.`, 'success');
                        fetchBranches()
                    })
                    .catch((error) => {
                        Swal.fire('Error!', `Failed to delete ${row.name}. ${error}`, 'error');
                    });
            }
        });
    };


    // Actions
    const actions = [
        {
            label: "View",
            icon: <Eye size={16} />,
            onClick: (row) => {                
                Swal.fire({
                    title: row.name,
                    html: `<p class="blue-shade"><strong>Address:</strong> ${row.address}</p>
                           <p class="blue-shade"><strong>Active:</strong> ${row.isActive ? 'Yes' : 'No'}</p>`,
                    icon: 'info',
                    confirmButtonText: 'Close',
                    customClass: {
                        popup: 'responsive-alert',
                    },
                    didOpen: () => {
                        // Apply custom colors to the title and text
                        const title = document.querySelector('.swal2-title');
                        const confirmBTN = document.querySelector('.swal2-confirm');
                        if (confirmBTN) {
                            confirmBTN.style.background = '#0A1E3F'; // Title color
                            confirmBTN.style.color = '#fff'; // Title color
                        }
                        if (title) {
                            title.style.color = '#0A1E3F'; // Title color
                        }
                    },
                    showClass: {
                        popup: 'animate__animated animate__fadeInDown'
                    },
                    hideClass: {
                        popup: 'animate__animated animate__fadeOutUp'
                    },
                });
            },
        },
        {
            label: "Edit",
            icon: <Edit size={16} />,
            onClick: (row) => showEditBranchAlert(row),
        },
        {
            label: "Delete",
            icon: <Trash size={16} />,
            onClick: (row) => showDeleteBranchAlert(row),
        },
    ];
    const data = branchData?.map(x =>{
        const logo = x?.logo?.data;
        const contentType = x?.logo?.contentType;  
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;      
        return {
            id : x?._id,
            name : x?.name,
            logo : logoBase64,
            address : x?.address,
            isActive : x?.isActive
        }
    })
    return (
        <div>
            <Layout active={'branch'} isAdmin={true}>
                <CardSection title={'Branches'} onClickEvent={addBranch} buttonText={'+ Add Branch'}>
                    <GenericTable
                        data={data}
                        columns={branchColumns}
                        recordsPerPage={10}
                        actions={actions}
                        action={true}
                    />
                </CardSection>
            </Layout>
        </div>
    );
};

export default Branches;
