import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Sidebar from '../sidebar/Sidebar';
import Header from '../Header/Header';
import { Menu } from 'lucide-react';
import { Outlet } from 'react-router-dom';
import Footer from '../footer/Footer';

const Layout = ({ children, sub_active, active, isAdmin = false }) => {
    const [isSidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setSidebarOpen(false);
            } else {
                setSidebarOpen(true);
            }
        };

        // Add event listener for resizing
        window.addEventListener('resize', handleResize);

        // Cleanup event listener on unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const toggleSidebar = () => setSidebarOpen(!isSidebarOpen);

    return (
        <Container fluid className="vh-100 overflow-hidden">
            <Row>
                {isSidebarOpen && (
                    <Col
                        xs={12}
                        md={4}
                        lg={2}
                        className={`p-0 ${isSidebarOpen ? 'd-block' : 'd-none d-lg-block'}`}
                    >
                        <Sidebar activeTab={active} subActive={sub_active} isAdmin={isAdmin} isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                    </Col>
                )}

                <Col xs={12} md={isSidebarOpen ? 8 : 12} lg={isSidebarOpen ? 10 : 12} className="p-0">
                    <Header toggleSidebar={toggleSidebar} sidebarOpen={isSidebarOpen} isAdmin={isAdmin} />
                    <div className='overflow-y-scroll w-100 vh-90'>
                        <Container className='p-4' fluid>
                            <main>
                                <Outlet />
                                {children}
                            </main>

                        </Container>
                        <Footer />
                    </div>
                </Col>

            </Row>

            {/* Menu button for medium and smaller screens */}
            {!isSidebarOpen && (
                <Button
                    onClick={toggleSidebar}
                    className="position-fixed d-md-block d-none border-0 bg-light" // Use d-md-none to hide button on md and up
                    style={{ top: '0.5rem', left: '1rem', color: "#0A1E3F" }}
                >
                    <Menu size={24} />
                </Button>
            )}
        </Container>
    );
};

export default Layout;
