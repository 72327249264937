import React from 'react';
import { Nav, Navbar, Button, Collapse } from 'react-bootstrap';
import { LayoutDashboard, Car, Users, MapPin, X, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Sidebar = ({ isOpen, toggleSidebar, isAdmin, activeTab, subActive }) => {
    const [openSubcategories, setOpenSubcategories] = useState({});

    const toggleSubcategories = (label) => {
        setOpenSubcategories((prevState) => ({
            ...prevState,
            [label]: !prevState[label]
        }));
    };
    const navItems = [
        { icon: LayoutDashboard, label: 'Dashboard', href: isAdmin ? '/' : '/driver', view: true },
        {
            icon: Car, label: 'Cars', view: true,
            subcategories: [
                { icon: null, label: 'Picked', href: isAdmin ? '/admin/dashboard/cars/picked' : '/driver/dashboard/cars/picked', view: true },
                { icon: null, label: 'Droped', href: isAdmin ? '/admin/dashboard/cars/droped' : '/driver/dashboard/cars/droped', view: true }
            ]
        },
        { icon: Users, label: 'Driver', href: '/admin/dashboard/drivers', view: isAdmin },
        { icon: MapPin, label: 'Branch', href: '/admin/dashboard/branches', view: isAdmin }
    ];

    return (
        <Navbar bg="light" variant="dark" className={`vh-100 shadow p-3 pt-0 ${isOpen ? 'd-block' : 'd-none d-lg-block'}`}>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <Navbar.Brand as={Link} to="/">
                    <img src="/assets/images/logo.png" alt="Logo" className='h-auto w-100' />
                </Navbar.Brand>
                {/* Close button only on medium screens and smaller */}
                {isOpen && (
                    <Button onClick={toggleSidebar} className="d-md-block bg-light border-0" style={{color: "#081e3c"}}>
                        <X size={20} />
                    </Button>
                )}
            </div>
            <Nav className="flex-column">
                {navItems.map((item) => (
                    <React.Fragment key={item.label}>
                        <Nav.Link
                            as={Link}
                            to={item.href}
                            className={`rounded-3 ${activeTab.toLowerCase() === item.label.toLowerCase() ? 'bg-blue text-white active' : 'blue-shade'} ${item.view ? 'd-flex align-items-center' : 'd-none'}`}
                            onClick={() => item.subcategories && toggleSubcategories(item.label)}
                        >
                            <item.icon className="me-2" />
                            {item.label}
                            {item.subcategories && <ChevronDown className="ms-auto" size={16} />}
                        </Nav.Link>

                        {/* Subcategories with a collapse feature */}
                        {item.subcategories && (
                            <Collapse in={openSubcategories[item.label]}>
                                <Nav className="flex-column py-3">
                                    {item.subcategories.map((sub) => (
                                        <Nav.Link
                                            onClick={() => item.subcategories && toggleSubcategories(item.label)}
                                            key={sub.label}
                                            as={Link}
                                            to={sub.href}
                                            className={`border-start ps-4 p-1 py-1 border-2 ${item.subcategories && subActive === sub.label ? 'text-primary border-primary' : 'blue-shade border-dark'} ${sub.view ? 'd-flex align-items-center' : 'd-none'}`}
                                        >
                                            {
                                                sub.icon && <sub.icon className="me-2" />
                                            }
                                            {sub.label}
                                        </Nav.Link>
                                    ))}
                                </Nav>
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
            </Nav>
        </Navbar>
    );
};

export default Sidebar;
