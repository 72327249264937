import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';

export function GenericTable({ columns, data, recordsPerPage = 10, action, actions }) {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(data.length / recordsPerPage);

  // Determine the records for the current page
  const startIndex = (currentPage - 1) * recordsPerPage;
  const currentRecords = data.slice(startIndex, startIndex + recordsPerPage);

  // Change page
  const goToPage = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  return (
    <div>
      <Table className='text-start bg-card' responsive>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th key={index}>{column.header}</th>
            ))}
            {action && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {currentRecords.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column, colIndex) => (
                <td key={colIndex} className='align-content-around' style={{color : "#081e3c"}} >
                  {column.type === 'image' ? (
                    <img
                      alt=''
                      src={row[column.accessor]}
                      className='rounded-circle profile-pic border border-1 border-secondary'
                    />
                  ) : column.type === 'checkbox' ? (
                    <input
                      type="checkbox"
                      checked={row[column.accessor]}
                      style={{
                        backgroundColor: row[column.accessor] ? '#0A1E3F' : 'transparent',
                        color: row[column.accessor] ? '#fff' : 'inherit',
                        borderColor: row[column.accessor] ? '#0A1E3F' : 'inherit',
                      }}
                      readOnly
                    />
                  ) : (
                    row[column.accessor]
                  )}
                </td>
              ))}
              {actions && (
                <td>
                  <div className="d-flex align-items-center justify-content-start gay-2">
                    {actions.map((action, actionIndex) => (
                      <button
                        key={actionIndex}
                        title={action.label}
                        onClick={() => action.onClick(row)}
                        className="btn border-0 btn-outline-dark btn-sm mx-1 d-flex align-items-center"
                        style={{color : "#081e3c"}}
                      >
                        {action.icon && (
                          <span className="me-1">{action.icon}</span>
                        )}
                      </button>
                    ))}
                  </div>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Pagination Controls */}
      <div className="pagination-controls d-flex gap-2 w-100 justify-content-end">
        <button
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage === 1}
          className='btn btn-outline-primary'
        >
          &#60;
        </button>

        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => goToPage(index + 1)}
            className={`btn ${currentPage === index + 1 ? 'btn-primary' : 'btn-outline-primary'}`}
          >
            {index + 1}
          </button>
        ))}

        <button
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage === totalPages}
          className='btn btn-outline-primary'
        >
          &gt;
        </button>
      </div>
    </div>
  );
}
