import React, { useEffect, useState } from 'react';
import axios from '../../config/server';
import Layout from '../../components/Layout/Layout';
import { Edit } from 'lucide-react';
import Swal from 'sweetalert2';
import ReactDOMServer from 'react-dom/server';
import { Form, Row, Col } from 'react-bootstrap';

const Profile = () => {
    const [adminDetails, setAdminDetails] = useState({});
    const [bannerHover, setBannerHover] = useState(false);
    const [loading, setLoading] = useState(true);

    // Fetch admin profile from the backend
    const fetchProfile = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/admin/profile`);
            const logo = response?.data?.banner?.data;
            const contentType = response?.data?.banner?.contentType;  
            const bannerImage = logo ? `data:${contentType};base64,${logo}` : null;
            setAdminDetails({...response.data, bannerImage});
        } catch (error) {
            console.error('Error fetching profile:', error);
            Swal.fire('Error!', 'Failed to load profile data.', 'error');
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {

        fetchProfile();
    }, []);

    // Handle banner change
    const changeBanner = async () => {
        Swal.fire({
            title: 'Change Banner',
            html: ReactDOMServer.renderToStaticMarkup(
                <Form className="text-start">
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Choose Banner</Form.Label>
                                <Form.Control type="file" id="bannerFile" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            ),
            showCancelButton: true,
            confirmButtonText: 'Update Banner',
            cancelButtonText: 'Cancel',
            preConfirm: async () => {
                const bannerFile = document.getElementById('bannerFile').files[0];
                if (!bannerFile) {
                    Swal.showValidationMessage('Please select a banner image!');
                    return false;
                }
                return bannerFile;
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.append('banner', result.value);

                try {
                    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/admin/profile/banner`, formData);
                    fetchProfile();
                    Swal.fire('Success!', 'Banner updated successfully.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'Failed to update banner.', 'error');
                }
            }
        });
    };

    // Handle password change
    const changePassword = () => {
        Swal.fire({
            title: 'Change Password',
            html: ReactDOMServer.renderToStaticMarkup(
                <Form className="text-start">
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control type="password" id="newPassword" placeholder="Enter new password" />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control type="password" id="confirmPassword" placeholder="Confirm password" />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            ),
            showCancelButton: true,
            confirmButtonText: 'Update Password',
            cancelButtonText: 'Cancel',
            preConfirm: () => {
                const newPassword = document.getElementById('newPassword').value;
                const confirmPassword = document.getElementById('confirmPassword').value;

                if (!newPassword || !confirmPassword) {
                    Swal.showValidationMessage('Please fill in all fields!');
                    return false;
                }
                if (newPassword !== confirmPassword) {
                    Swal.showValidationMessage('Passwords do not match!');
                    return false;
                }
                return newPassword;
            },
        }).then(async(result) => {
            if (result.isConfirmed) {
                const newPassword = document.getElementById('newPassword').value;
                const confirmPassword = document.getElementById('confirmPassword').value;
                const formData = new FormData();
                formData.append('test', newPassword);
                formData.append('confirmPassword', confirmPassword);

                try {
                    const response = await axios.patch(`${process.env.REACT_APP_API_URL}/admin/password/${newPassword}/${confirmPassword}`, formData);
                    fetchProfile();
                    // Handle password update logic here
                    Swal.fire('Success!', 'Password updated successfully.', 'success');
                } catch (error) {
                    console.log('error', error);
                    
                    Swal.fire('Error!', 'Failed to update Password.', 'error');
                }
            }
        });
    };

    if (loading) return <p>Loading...</p>;

    return (
        <div>
            <Layout active={''} isAdmin={true}>
                <div className="rounded-5 bg-light position-relative"    onMouseEnter={() => setBannerHover(true)}
                        onMouseLeave={() => setBannerHover(false)}>
                    <img
                        src={adminDetails?.bannerImage || '/assets/images/logo.png'}
                        className="w-100 rounded-5"
                        style={{height : "300px"}}
                        alt="hero"
                    />
                    {bannerHover && (
                        <div className="position-absolute bottom-0 end-0 py-3 px-3">
                            <Edit
                                size={24}
                                onClick={changeBanner}
                                className="text-white cursor-pointer"
                            />
                        </div>
                    )}
                </div>
                <div className="w-100 h-auto" style={{marginTop: "-70px"}}>
                    <img
                        src={adminDetails.profilePicture?.path || '/assets/images/default-profile.png'}
                        className="rounded-circle h-5p w-5p mx-auto"
                        style={{zIndex: "222222", position: "relative"}}
                        alt="profile pic"
                    />
                </div>
                <div className="d-flex align-items-center justify-content-between h-auto mt-4">
                    <h3 className='blue-shade'>{adminDetails.name || 'Admin Name'}</h3>
                    <button className="btn bg-light blue-shade" onClick={changePassword}>
                        Change Password
                    </button>
                </div>
            </Layout>
        </div>
    );
};

export default Profile;
