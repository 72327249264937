import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import {carColumns} from '../admin/PickedCars'
import Swal from 'sweetalert2';
import axios from '../../config/server';
import { Eye, Trash2, Edit, CheckCircle } from 'lucide-react';
import { Form } from 'react-bootstrap';
import ReactDOM from 'react-dom';


const DriverPickedCars = () => {
    const [data, setData] = useState([]); // Manage the car data in state
    const [branches, setBranches] = useState([]); // Manage the car data in state
    const user = JSON.parse(localStorage.getItem('user'));

    // Fetch car data from backend on component mount
    const fetchCars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cars`);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching car data:', error);
        }
    };
    const fetchBranches = async () => {
        try {
            const branchesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(branchesResponse.data); // Fetch branches
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire('Error', 'Failed to fetch data.', 'error');
        }
    };
    useEffect(() => {
        fetchCars();
        fetchBranches()
    }, []); // Empty array ensures this runs once when the component is mounted

    // Show details for a car (View action)
    const showViewAlert = (row) => {
        const htmlContent = `<div class="text-start">
            <p><strong>Customer Mobile:</strong> ${row.customerMobile}</p>
            <p><strong>Car Brand:</strong> ${row.carBrand}</p>
            <p><strong>License Plate:</strong> ${row.licensePlate}</p>
            <p><strong>Driver:</strong> ${row.driver}</p>
            <p><strong>Branch:</strong> ${row.branch}</p>
            <p><strong>Pickup Time:</strong> ${new Date(row.pickupTime).toLocaleString()}</p>
            <p><strong>Drop-Off Time:</strong> ${row.dropOffTime ? new Date(row.dropOffTime).getFullYear() > 2000 ? new Date(row.dropOffTime).toLocaleDateString() : "--------" : "-----"}</p>
        </div>`;

        Swal.fire({
            title: `<h3 style="color: #0A1E3F;">Car Details: ${row.carBrand} (${row.licensePlate})</h3>`,
            html: htmlContent,
            icon: 'info',
            confirmButtonText: 'Close',
            didOpen: ()=>{
                const confirmBTN = document.querySelector('.swal2-confirm');
                const title = document.querySelector('.swal2-title');
                if (title) {
                    title.style.color = '#0A1E3F'; // Set the title color
                }
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
            },
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        });
    };

    // Edit a car's details
    const editCar = (row) => {
        Swal.fire({
            title: 'Edit Car Details',
            html: `
                <input id="carBrand" class="swal2-input custom-placeholder" placeholder="Car Brand" value="${row.carBrand}" required>
                <input id="licensePlate" class="swal2-input custom-placeholder" placeholder="License Plate" value="${row.licensePlate}" required>
            `,
            confirmButtonText: 'Update',
            cancelButtonText: 'Cancel',
            didOpen: ()=>{
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
            },
            focusConfirm: false,
            preConfirm: async () => {
                const carBrand = document.getElementById('carBrand').value;
                const licensePlate = document.getElementById('licensePlate').value;
                const updatedCar = { ...row, carBrand, licensePlate, dropOffTime : null };

                try {
                    await axios.patch(`${process.env.REACT_APP_API_URL}/cars/${row._id}`, updatedCar);
                    fetchCars()
                    Swal.fire('Updated!', 'Car details have been updated.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue updating the car.', 'error');
                }
            }
        });
    };

    // Delete a car
    const deleteCar = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete the car with License Plate: ${row.licensePlate}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`/api/cars/${row._id}`);
                    setData(data.filter(car => car._id !== row._id));
                    Swal.fire('Deleted!', 'The car has been deleted.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue deleting the car.', 'error');
                }
            }
        });
    };

    // Drop car (mark as dropped)
    const dropCar = (row) => {
        Swal.fire({
            title: 'Mark Car as Dropped?',
            text: `Do you want to mark the car with License Plate: ${row.licensePlate} as dropped?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Drop it',
            cancelButtonText: 'Cancel',
            didOpen: ()=>{
                const confirmBTN = document.querySelector('.swal2-confirm');
                const title = document.querySelector('.swal2-title');
                if (title) {
                    title.style.color = '#0A1E3F'; // Set the title color
                }
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
            },
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const updatedCar = { ...row, status: "droped", dropOffTime : Date.now() };
                    await axios.patch(`${process.env.REACT_APP_API_URL}/cars/${row._id}`, updatedCar);
                    fetchCars()
                    Swal.fire('Dropped!', 'The car has been marked as dropped.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue marking the car as dropped.', 'error');
                }
            }
        });
    };

    // Add a new car
    const addCar = () => {
        Swal.fire({
            title: 'Add New Car',
            html: `
                <input id="newCarBrand" class="swal2-input custom-placeholder" placeholder="Car Brand" required>
                <input id="newLicensePlate" class="swal2-input custom-placeholder" placeholder="License Plate" required>
                <input id="newCustomerMobile" class="swal2-input custom-placeholder" placeholder="Customer Mobile" required>
                <div id="branchSelectContainer"></div> <!-- This is where we will inject the select -->
            `,
            focusConfirm: false,
            confirmButtonText: 'Pick Up',
            cancelButtonText: 'Cancel',
            didOpen: () => {
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (title) {
                    title.style.color = '#0A1E3F'; // Set the title color
                }
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
            },
            preConfirm: async () => {
                const carBrand = document.getElementById('newCarBrand').value;
                const licensePlate = document.getElementById('newLicensePlate').value;
                const customerMobile = document.getElementById('newCustomerMobile').value;
                const branch = user?.branch;
                const newCar = { carBrand, licensePlate, customerMobile, branch };

                 // Validate inputs
                 if (!carBrand) {
                    Swal.showValidationMessage('Car Brand is required');
                    return false;
                }
                if (!licensePlate) {
                    Swal.showValidationMessage('License Plate is required');
                    return false;
                }
                if (!customerMobile) {
                    Swal.showValidationMessage('Customer Mobile is required');
                    return false;
                }
                if (!/^\d{10}$/.test(customerMobile)) {
                    Swal.showValidationMessage('Customer Mobile must be a valid 10-digit number');
                    return false;
                }
                if (!branch) {
                    Swal.showValidationMessage('Please choose a branch');
                    return false;
                }

                
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/cars/add`, newCar);
                    fetchCars()
                    Swal.fire('Added!', 'New car has been added.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue adding the car.', 'error');
                }
            }
        });
    };

    // Actions for View, Edit, Delete, and Drop it
    const actions = [
        {
            label: "View",
            icon: <Eye size={16} />,
            onClick: (row) => showViewAlert(row),
        },
        {
            label: "Edit",
            icon: <Edit size={16} />,
            onClick: (row) => editCar(row),
        },
        // {
        //     label: "Delete",
        //     icon: <Trash2 size={16} />,
        //     onClick: (row) => deleteCar(row),
        // },
        {
            label: "Drop it",
            icon: <CheckCircle size={16} />,
            onClick: (row) => dropCar(row),
        },
    ];

    const tableData = data?.filter(x => x?.status === "picked")?.map(x=>{
        const logo = x?.carBrandLogo?.data;
        const contentType = x?.carBrandLogo?.contentType;  
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;   
        return {
            name : x?.name,
            branch : x?.branch?.name,
            branchId : x?.branch?._id,
            carBrandLogo : logoBase64,
            driver : x?.driver?.name,
            customerMobile : x?.customerMobile,
            carBrand : x?.carBrand,
            licensePlate : x?.licensePlate,
            pickupTime : new Date(x?.pickupTime).toLocaleDateString() + " "+  new Date(x?.pickupTime).toLocaleTimeString(),
            _id : x?._id,
            status : x?.status,
            dropOffTime : x?.dropOffTime ? new Date(x?.dropOffTime)?.toLocaleDateString()  + " "+  new Date(x?.dropOffTime).toLocaleTimeString() : "-----"
        }
    })
    return (
        <div>
            <Layout active='cars' sub_active='Picked' isAdmin={false}>
                <CardSection title={'Picked up Cars'} buttonText={'+ Add a Car'} onClickEvent={addCar}>
                    <GenericTable data={tableData} columns={carColumns} action={true} actions={actions} />
                </CardSection>
            </Layout>
        </div>
    );
};

export default DriverPickedCars;
