import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';

const COLORS = ['#4f46e5', '#10b981'];

const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div
                style={{
                    backgroundColor: '#1e242f',
                    color: '#fff',
                    padding: '5px',
                    borderRadius: '5px',
                    textAlign: 'center'
                }}
            >
                <p style={{ margin: 0 }}>{`${payload[0].name}: ${payload[0].value}`}</p>
            </div>
        );
    }
    return null;
};

const CustomPieChart = ({ pieData }) => {
    return (
        <ResponsiveContainer width="100%" height={300}>
            <PieChart>
                <Tooltip content={<CustomTooltip />} />
                <Pie
                    data={pieData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={60} // Donut chart
                    fill="#4f46e5"
                    isAnimationActive={false}
                >
                    {pieData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default CustomPieChart;
