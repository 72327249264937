import React from 'react';
import { Card } from 'react-bootstrap';

const CardSection = ({ title, children, buttonText, onClickEvent }) => {
    return (
        <Card className='border-white border'>
            <Card.Header className='border-white'>
                <Card.Title className='d-flex justify-content-between mb-0 align-items-center' style={{color : "#081e3c"}}>
                    <div>{title}</div>
                    {buttonText && <button onClick={onClickEvent} style={{color : '#081e3c'}} type='button' className='btn my-auto btn-outline-primary'>{buttonText}</button>}
                </Card.Title>
            </Card.Header>
            <Card.Body>{children}</Card.Body>
        </Card>
    )
}

export default CardSection;