import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Dropdown, Image, Button } from 'react-bootstrap';
import { Menu } from 'lucide-react';
import { useAuth } from '../../auth/AuthProvider';

const Header = ({ toggleSidebar, sidebarOpen, isAdmin }) => {
    const [adminDetails, setAdmindetails] = useState({});
    const auth = useAuth();

    useEffect(() => {
        const admin = JSON.parse(localStorage.getItem('user'));

        setAdmindetails(admin);
    }, [])

    return (
        <Navbar bg="light" variant="dark" className="justify-content-between shadow px-3 shadow-sm">

            {!sidebarOpen && (
                <Button onClick={toggleSidebar} className="text-white border-0 bg-light d-md-none cursor-pointer">
                    <Menu size={20} />
                </Button>
            )}

            <Nav className="ms-auto">
                <Dropdown align="end">
                    <Dropdown.Toggle as="div" className="d-flex align-items-center">
                        <Image
                            src={adminDetails?.profilepic ? adminDetails?.profilepic : '/assets/images/default-profile.png'}
                            roundedCircle
                            width="40"
                            height="40"
                            alt="Profile"
                            className="me-2"
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='bg-card shadow py-0'>
                        <Dropdown.Item className='py-2' href={isAdmin ? '/admin/profile' : '/driver/profile'}>My Profile</Dropdown.Item>
                        <Dropdown.Divider className='border-white my-0' />
                        <Dropdown.Item href="#" className='py-2' onClick={() => auth.logOut()}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Nav>
        </Navbar>
    );
};

export default Header;
