import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import Swal from 'sweetalert2';
import axios from '../../config/server';
import { Eye, Trash2, Edit, CheckCircle } from 'lucide-react';
import { Button, Form } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment-timezone';

export const carColumns = [
    { header: "Customer Mobile", accessor: "customerMobile", type: "text" }, // Customer Mobile
    { header: "Car Logo", accessor: "carBrandLogo", type: "image" },              // Car Brand
    { header: "Car Brand", accessor: "carBrand", type: "text" },              // Car Brand
    { header: "License Plate", accessor: "licensePlate", type: "text" },      // License Plate
    { header: "Driver", accessor: "driver", type: "text" },                   // Driver
    { header: "Branch", accessor: "branch", type: "text" },                   // Branch
    { header: "Pickup Time", accessor: "pickupTime", type: "date" },          // Pickup Time
    { header: "Drop Time", accessor: "dropOffTime", type: "date" },          // Pickup Time
];

const PickedCars = () => {
    const [data, setData] = useState([]); // Manage the car data in state
    const [branches, setBranches] = useState([]); // Manage the car data in state
    const [filteredData, setFilteredData] = useState([]);
    const [selectedFilter, setSelectedFilter] = useState('ALL');
    const [selectedBranch, setSelectedBranch] = useState("ALL");
    // Fetch car data from backend on component mount
    const fetchCars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cars`);
            setData(response.data);
            setFilteredData(response.data);
        } catch (error) {
            console.error('Error fetching car data:', error);
        }
    };
    const fetchBranches = async () => {
        try {
            const branchesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(branchesResponse.data); // Fetch branches
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire('Error', 'Failed to fetch data.', 'error');
        }
    };
    useEffect(() => {
        fetchCars();
        fetchBranches()
    }, []); // Empty array ensures this runs once when the component is mounted

    // Show details for a car (View action)
    const showViewAlert = (row) => {
        const htmlContent = `<div class="text-start">
            <p style="color : #0A1E3F"><strong>Customer Mobile:</strong> ${row.customerMobile}</p>
            <p style="color : #0A1E3F"><strong>Car Brand:</strong> ${row.carBrand}</p>
            <p style="color : #0A1E3F"><strong>License Plate:</strong> ${row.licensePlate}</p>
            <p style="color : #0A1E3F"><strong>Driver:</strong> ${row.driver}</p>
            <p style="color : #0A1E3F"><strong>Branch:</strong> ${row.branch}</p>
            <p style="color : #0A1E3F"><strong>Pickup Time:</strong> ${new Date(row.pickupTime).toLocaleString()}</p>
            <p style="color : #0A1E3F"><strong>Drop-Off Time:</strong> ${row.dropOffTime ? new Date(row.dropOffTime).getFullYear() > 2000 ? new Date(row.dropOffTime).toLocaleDateString() : "--------" : "-----"}</p>
        </div>`;

        Swal.fire({
            title: `<h3 style="color: #0A1E3F;">Car Details: ${row.carBrand} (${row.licensePlate})</h3>`,
            html: htmlContent,
            icon: 'info',
            didOpen: () => {
                const title = document.querySelector('.swal2-title');
                if (title) {
                    title.style.color = '#0A1E3F'; // Set the title color
                }
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
            },
            confirmButtonText: 'Close',
            customClass: {
                popup: 'responsive-alert',
            },
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
        });
    };

    // Edit a car's details
    const editCar = (row) => {
        Swal.fire({
            title: 'Edit Car Details',
            html: `
                <input id="carBrand" class="swal2-input blue-shade" placeholder="Car Brand" value="${row.carBrand}" required>
                <input id="licensePlate" class="swal2-input blue-shade" placeholder="License Plate" value="${row.licensePlate}" required>
            `,
            focusConfirm: false,
            confirmButtonText: 'Update',
            cancelButtonText: 'Cancel',
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
            },
            preConfirm: async () => {
                const carBrand = document.getElementById('carBrand').value;
                const licensePlate = document.getElementById('licensePlate').value;
                const updatedCar = { ...row, carBrand, licensePlate, dropOffTime: null };

                try {
                    await axios.patch(`${process.env.REACT_APP_API_URL}/cars/${row._id}`, updatedCar);
                    fetchCars()
                    Swal.fire('Updated!', 'Car details have been updated.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue updating the car.', 'error');
                }
            }
        });
    };

    // Delete a car
    const deleteCar = (row) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to delete the car with License Plate: ${row.licensePlate}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete',
            cancelButtonText: 'Cancel',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`/api/cars/${row._id}`);
                    setData(data.filter(car => car._id !== row._id));
                    Swal.fire('Deleted!', 'The car has been deleted.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue deleting the car.', 'error');
                }
            }
        });
    };

    // Drop car (mark as dropped)
    const dropCar = (row) => {
        Swal.fire({
            title: 'Mark Car as Dropped?',
            text: `Do you want to mark the car with License Plate: ${row.licensePlate} as dropped?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Drop it',
            cancelButtonText: 'Cancel',
            didOpen: () => {
                // Apply custom colors to the title and text
                const title = document.querySelector('.swal2-title');
                const content = document.querySelector('.swal2-html-container');
                const confirmBTN = document.querySelector('.swal2-confirm');
                if (confirmBTN) {
                    confirmBTN.style.background = '#0A1E3F'; // Set the title color
                    confirmBTN.style.color = '#fff'; // Set the title color
                }
                if (title) {
                    title.style.color = '#0A1E3F'; // Title color
                }
                if (content) {
                    content.style.color = '#0A1E3F'; // Text color
                }
            }
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const updatedCar = { ...row, status: "droped", dropOffTime: moment().tz('UTC').format() };
                    await axios.patch(`${process.env.REACT_APP_API_URL}/cars/${row._id}`, updatedCar);
                    fetchCars()
                    Swal.fire('Dropped!', 'The car has been marked as dropped.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue marking the car as dropped.', 'error');
                }
            }
        });
    };

    // Add a new car
    const addCar = () => {
        Swal.fire({
            title: 'Add New Car',
            html: `
                <input id="newCarBrand" class="swal2-input blue-shade custom-placeholder" placeholder="Car Brand Name" required>
                <div id="logoDropdownContainer"></div> <!-- Logo dropdown container -->
                <input id="newLicensePlate" class="swal2-input blue-shade custom-placeholder" placeholder="License Plate" required>
                <input id="newCustomerMobile" class="swal2-input blue-shade custom-placeholder" placeholder="Customer Mobile" required>
                <div id="branchSelectContainer"></div> <!-- Branch dropdown container -->
            `,
            focusConfirm: false,
            didOpen: () => {
                // Render Branch Dropdown
                const branchSelectContainer = document.getElementById('branchSelectContainer');
                if (branchSelectContainer) {
                    const branchSelect = (
                        <Form.Select
                            className="mx-auto"
                            id="newBranch"
                            defaultValue=""
                            required
                            style={{
                                width: "58%",
                                paddingTop: "10px",
                                paddingBottom: "10px",
                                marginTop: "10px",
                                backgroundColor: "white",
                                color: "#0A1E3F",
                                border: "1px solid lightgray",
                            }}
                        >
                            <option value="" disabled>Choose Branch</option>
                            {branches.map((branch, index) => (
                                <option value={branch._id} key={index}>{branch.name}</option>
                            ))}
                        </Form.Select>
                    );
                    ReactDOM.render(branchSelect, branchSelectContainer);
                }

                // Render Logo Dropdown
                const logoDropdownContainer = document.getElementById('logoDropdownContainer');
                if (logoDropdownContainer) {
                    const logos = [
                        { id: 'alto', src: '/assets/images/cars/alto.png', alt: 'Alto' },
                        { id: 'sazuki', src: '/assets/images/cars/sazuki.png', alt: 'Sazuki' },
                        { id: 'ford', src: '/assets/images/cars/ford.png', alt: 'Ford' },
                    ];

                    const logoDropdown = (
                        <div style={{ marginTop: '10px', textAlign: 'center' }}>
                            <div
                                className="custom-dropdown"
                                style={{
                                    width: '58%',
                                    margin: '0 auto',
                                    zIndex: "1000",
                                    padding: '10px',
                                    border: '1px solid lightgray',
                                    borderRadius: '5px',
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    cursor: 'pointer',
                                    textAlign: 'left',
                                }}
                            >
                                <span id="selectedLogoText" style={{ color: '#0A1E3F' }}>Choose Brand Logo</span>
                                <div
                                    className="dropdown-options"
                                    style={{
                                        display: 'none',
                                        position: 'absolute',
                                        top: '100%',
                                        left: '0',
                                        right: '0',
                                        zIndex: 10,
                                        backgroundColor: 'white',
                                        border: '1px solid lightgray',
                                        borderRadius: '5px',
                                        maxHeight: '200px',
                                        overflowY: 'auto',
                                    }}
                                    id="logoOptions"
                                >
                                    {logos.map((logo) => (
                                        <div
                                            key={logo.id}
                                            className="dropdown-option"
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                padding: '5px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                // Update the selected logo
                                                const selectedLogoText = document.getElementById('selectedLogoText');
                                                if (selectedLogoText) {
                                                    selectedLogoText.innerHTML = `<img src="${logo.src}" alt="${logo.alt}" style="width:20px; height:20px; margin-right:5px;"> ${logo.alt}`;
                                                }
                                                const selectedLogoFile = document.getElementById('selectedLogoFile');
                                                if (selectedLogoFile) {
                                                    selectedLogoFile.value = logo.src;
                                                }

                                                // Hide options
                                                const logoOptions = document.getElementById('logoOptions');
                                                if (logoOptions) logoOptions.style.display = 'none';
                                            }}
                                        >
                                            <img
                                                src={logo.src}
                                                alt={logo.alt}
                                                style={{ width: '30px', height: '35px', marginRight: '10px' }}
                                            />
                                            {logo.alt}
                                        </div>
                                    ))}
                                </div>
                                <input type="hidden" id="selectedLogoFile" value="" />
                            </div>
                        </div>
                    );
                    ReactDOM.render(logoDropdown, logoDropdownContainer);

                    // Toggle dropdown visibility
                    const dropdown = document.querySelector('.custom-dropdown');
                    if (dropdown) {
                        dropdown.addEventListener('click', () => {
                            const logoOptions = document.getElementById('logoOptions');
                            if (logoOptions) {
                                logoOptions.style.display = logoOptions.style.display === 'none' ? 'block' : 'none';
                            }
                        });
                    }
                }
            },
            confirmButtonText: 'Pick Up',
            cancelButtonText: 'Cancel',
            preConfirm: async () => {
                const carBrand = document.getElementById('newCarBrand').value;
                const licensePlate = document.getElementById('newLicensePlate').value;
                const customerMobile = document.getElementById('newCustomerMobile').value;
                const branch = document.getElementById('newBranch').value;
                const selectedLogoFile = document.getElementById('selectedLogoFile').value;

                // Validation
                if (!carBrand || !licensePlate || !customerMobile || !branch || !selectedLogoFile) {
                    Swal.showValidationMessage('All fields are required');
                    return false;
                }

                try {
                    const logoFile = await fetch(selectedLogoFile)
                        .then((response) => response.blob())
                        .then((blob) => new File([blob], "car_logo.png", { type: blob.type }));

                    const formData = new FormData();
                    formData.append('carBrand', carBrand);
                    formData.append('licensePlate', licensePlate);
                    formData.append('customerMobile', customerMobile);
                    formData.append('branch', branch);
                    formData.append('logo', logoFile);

                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/cars/add`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' },
                    });

                    fetchCars();
                    Swal.fire('Added!', 'New car has been added.', 'success');
                } catch (error) {
                    Swal.fire('Error!', 'There was an issue adding the car.', 'error');
                }
            },
        });
    };



    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        applyFilters(filter, selectedBranch);
    };

    const handleBranchChange = (branch) => {
        setSelectedBranch(branch);
        applyFilters(selectedFilter, branch);
    };

    // Actions for View, Edit, Delete, and Drop it
    const actions = [
        {
            label: "View",
            icon: <Eye size={16} />,
            onClick: (row) => showViewAlert(row),
        },
        {
            label: "Edit",
            icon: <Edit size={16} />,
            onClick: (row) => editCar(row),
        },
        // {
        //     label: "Delete",
        //     icon: <Trash2 size={16} />,
        //     onClick: (row) => deleteCar(row),
        // },
        {
            label: "Drop it",
            icon: <CheckCircle size={16} />,
            onClick: (row) => dropCar(row),
        },
    ];
    const applyFilters = (timeFilter, branchFilter) => {
        const now = moment();
        const filtered = data.filter((car) => {
            const pickupTime = moment(car.pickupTime);

            const timeMatch = (() => {
                switch (timeFilter) {
                    case "1_WEEK":
                        return pickupTime.isSame(now, "week");
                    case "1_MONTH":
                        return pickupTime.isSame(now, "month");
                    case "3_MONTHS":
                        return pickupTime.isBetween(
                            now.clone().subtract(3, "months").startOf("month"),
                            now.endOf("month"),
                            null,
                            "[]"
                        );
                    case "6_MONTHS":
                        return pickupTime.isBetween(
                            now.clone().subtract(6, "months").startOf("month"),
                            now.endOf("month"),
                            null,
                            "[]"
                        );
                    case "1_YEAR":
                        return pickupTime.isSame(now, "year");
                    default:
                        return true;
                }
            })();

            const branchMatch =
                branchFilter === "ALL" || car.branch?._id === branchFilter;

            return timeMatch && branchMatch;
        });

        setFilteredData(filtered);
    };


    const tableData = filteredData?.filter(x => x?.status === "picked")?.map(x => {
        const logo = x?.carBrandLogo?.data;
        const contentType = x?.carBrandLogo?.contentType;
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;
        return {
            name: x?.name,
            branch: x?.branch?.name,
            branchId: x?.branch?._id,
            carBrandLogo: logoBase64,
            driver: x?.driver?.name,
            customerMobile: x?.customerMobile,
            carBrand: x?.carBrand,
            licensePlate: x?.licensePlate,
            pickupTime: new Date(x?.pickupTime).toLocaleDateString() + " " + new Date(x?.pickupTime).toLocaleTimeString(),
            _id: x?._id,
            status: x?.status,
            dropOffTime: x?.dropOffTime ? new Date(x?.dropOffTime)?.toLocaleDateString() + " " + new Date(x?.dropOffTime).toLocaleTimeString() : "-----"
        }
    })
    const validateStringAsBase64 = (str) => {
        try {
            // Check if the string is a valid base64 image string (simplified check)
            const regex = /^data:image\/[a-zA-Z]+;base64,/;
            return regex.test(str);
        } catch (error) {
            return false;
        }
    };
    const exportPDF = (data) => {
        const doc = new jsPDF("portrait", "mm", "a4");
        // const pageWidth = 210; // A4 width in mm
        // const pageHeight = 297; // A4 height in mm
        // const margin = 5; // Margin for the rounded border
        // doc.roundedRect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin, 5, 5); // Adds rounded border with a radius of 5
        // Add RZANA logo
        doc.addImage('/assets/images/logo.png', 'PNG', 10, 7, 40, 20); // Adjust position/size as needed

        // Add Hilton text with border
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        const branch = branches?.filter(x => x?._id?.toString() === selectedBranch)[0]
        const logo = branch?.logo?.data;
        const contentType = branch?.logo?.contentType;
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;
        const pageWidth = doc.internal.pageSize.width;
        const logoWidth = 40;
        const xPosition = (pageWidth - logoWidth) / 2;

        doc.addImage(logoBase64 ? logoBase64 : '/assets/images/logo.png', 'PNG', xPosition, 10, logoWidth, 15);
        // doc.addImage(logoBase64, 105, 22, { align: "center" }); // Center Hilton text
        doc.rect(xPosition - 1.5, 8.5, 43, 18); // Draw rectangle around Hilton

        let fromDate, toDate;

        if (selectedFilter === "ALL") {
            fromDate = "All";
            toDate = "All";
        } else {
            toDate = moment().format("DD/MM/YYYY"); // Current date as "TO"
            switch (selectedFilter) {
                case "1_WEEK":
                    fromDate = moment().subtract(1, 'week').format("DD/MM/YYYY");
                    break;
                case "1_MONTH":
                    fromDate = moment().subtract(1, 'month').format("DD/MM/YYYY");
                    break;
                case "3_MONTHS":
                    fromDate = moment().subtract(3, 'months').format("DD/MM/YYYY");
                    break;
                case "6_MONTHS":
                    fromDate = moment().subtract(6, 'months').format("DD/MM/YYYY");
                    break;
                case "1_YEAR":
                    fromDate = moment().subtract(1, 'year').format("DD/MM/YYYY");
                    break;
                default:
                    fromDate = "All";
            }
        }
        // Add From and To dates
        doc.setFontSize(10);
        doc.text("FROM :", 150, 16);
        doc.text(fromDate, 170, 16);
        doc.text("TO :", 150, 22);
        doc.text(toDate, 170, 22);

        // Horizontal Divider
        doc.setDrawColor(0);
        doc.line(10, 30, 200, 30);

        // Progress Report Heading
        doc.setFontSize(14);
        doc.text("PROGRESS SHEET", 105, 40, { align: "center" });

        // Table Data Preparation
        const tableColumns = [
            "S. No.",
            "Car Brand",
            "Customer No.",
            "Plate No.",
            "Date",
            "Pickup Time",
            "Drop Off Time",
        ];

        const tableRows = data.map((item, index) => {
            const isValidBase64 = validateStringAsBase64(item.carBrandLogo);
            const carBrandLogo = isValidBase64 ? item.carBrandLogo : '/assets/images/cars/alto.png';

            return {
                rowData: [
                    String(index + 1).padStart(2, "0"), // S. No
                    " ", // Placeholder for image
                    item.customerMobile,
                    item.licensePlate,
                    item.pickupTime.split(" ")[0],
                    item.pickupTime.split(" ")[1],
                    item.dropOffTime,
                ],
                carBrandLogo,
            };
        });


        // Add Table
        doc.autoTable({
            head: [tableColumns],
            body: tableRows.map((row) => row.rowData),
            startY: 50,
            theme: "grid",
            headStyles: {
                fillColor: [255, 255, 255], // White background
                textColor: [0, 0, 0],       // Black text
                fontStyle: "bold",         // Bold header text
            },
            styles: {
                fontSize: 10,
                cellPadding: 3,
                lineColor: [0, 0, 0],
                lineWidth: 0.5,
            },
            didDrawCell: function (data) {
                if (data.column.index === 1 && data.cell.section === 'body') {
                    const logoBase64 = tableRows[data.row.index].carBrandLogo;
                    const cellWidth = data.cell.width;
                    const cellHeight = data.cell.height;
                    const imgSize = 9;
                    const x = data.cell.x + (cellWidth - imgSize) / 2;
                    const y = data.cell.y + (cellHeight - imgSize) / 2;

                    doc.setDrawColor(0);
                    doc.setFillColor(255, 255, 255);
                    doc.roundedRect(x, y, imgSize, imgSize, imgSize / 2, imgSize / 2, 'F');
                    doc.addImage(logoBase64, 'PNG', x, y, imgSize, imgSize, undefined, 'S');
                }
            }
        });


        // Save PDF
        doc.save("Progress_Sheet.pdf");
    };
    return (
        <div>
            <Layout active="cars" sub_active="Picked" isAdmin={true}>
                <div className="d-flex justify-content-between mb-3">
                    <div className="d-flex align-items-center">
                        <Form.Select
                            onChange={(e) => handleBranchChange(e.target.value)}
                            value={selectedBranch}
                            className="me-2"
                            style={{ background: "#0A1E3F", height: "40px" }}
                        >
                            <option value="ALL">All Branches</option>
                            {branches.map((branch) => (
                                <option key={branch._id} value={branch._id}>
                                    {branch.name}
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Select
                            onChange={(e) => handleFilterChange(e.target.value)}
                            value={selectedFilter}
                            className="me-2"
                            style={{ background: "#0A1E3F", height: "40px" }}
                        >
                            <option value="ALL">All</option>
                            <option value="1_WEEK">Last 1 Week</option>
                            <option value="1_MONTH">Last 1 Month</option>
                            <option value="3_MONTHS">Last 3 Months</option>
                            <option value="6_MONTHS">Last 6 Months</option>
                            <option value="1_YEAR">Last 1 Year</option>
                        </Form.Select>
                    </div>
                    <Button onClick={() => exportPDF(tableData)} className="bg-blue">
                        Export to PDF
                    </Button>
                </div>
                <CardSection
                    title={"Picked up Cars"}
                    buttonText={"+ Add a Car"}
                    onClickEvent={addCar}
                >
                    <GenericTable
                        data={tableData}
                        columns={carColumns}
                        action={true}
                        actions={actions}
                    />
                </CardSection>
            </Layout>
        </div>
    );
};

export default PickedCars;
