import React from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div className='border-top border-1 border-secondary'>
            <Container fluid className='p-4 py-3'>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="text-center">
                        <Link to={'https://www.rzana.co'} className='me-2 text-decoration-none' style={{color: "#0A1E3F"}}>RZANA</Link><span style={{color: "#081e3c"}}>&copy; {new Date().getFullYear()} All Rights reserved</span>
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Footer;