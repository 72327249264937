import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Layout from '../../components/Layout/Layout';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import { Eye } from 'lucide-react';
import axios from 'axios';

export const usersColumns = [
    { header: "Full Name", accessor: "fullName", type: "text" },
    { header: "Email", accessor: "email", type: "text" },
    { header: "Subject", accessor: "subject", type: "text" },
    { header: "Message", accessor: "message", type: "text" }
];

export const usersData = [
    { fullName: "Mark Otto", email: "mark@example.com", subject: 'Testing RZANA', message: "NY" },
    { fullName: "Jacob Thornton", email: "jacob@example.com", subject: 'Testing RZANA', message: "LA" },
    { fullName: "Mark Otto", email: "mark@example.com", subject: 'Testing RZANA', message: "NY" },
    { fullName: "Jacob Thornton", email: "jacob@example.com", subject: 'Testing RZANA', message: "LA" },
    { fullName: "Mark Otto", email: "mark@example.com", subject: 'Testing RZANA', message: "NY" },
    { fullName: "Jacob Thornton", email: "jacob@example.com", subject: 'Testing RZANA', message: "LA" },
    { fullName: "Mark Otto", email: "mark@example.com", subject: 'Testing RZANA', message: "NY" },
    { fullName: "Jacob Thornton", email: "jacob@example.com", subject: 'Testing RZANA', message: "LA" },
    { fullName: "Mark Otto", email: "mark@example.com", subject: 'Testing RZANA', message: "NY" },
    { fullName: "Jacob Thornton", email: "jacob@example.com", subject: 'Testing RZANA', message: "LA" },
    { fullName: "Mark Otto", email: "mark@example.com", subject: 'Testing RZANA', message: "NY" },
    { fullName: "Jacob Thornton", email: "jacob@example.com", subject: 'Testing RZANA', message: "LA" },
];

const Users = () => {
    const [userData, setUserData] = useState(usersData);

    // Load user data from local storage
    // useEffect(() => {
    //     const storedData = localStorage.getItem('usersData');
    //     if (storedData) {
    //         setUserData(JSON.parse(storedData)); // Load from local storage if data is available
    //     }
    // }, []);

    // Show details for a user (commented API call)
    /*
    const showUserDetails = async (row) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${row.email}`);
            const userDetails = response.data;

            Swal.fire({
                title: `<h3 style="color: #2E86C1;">${userDetails.fullName}</h3>`,
                html: `<div class="text-start">
                        <p class="text-start"><strong>Email:</strong> ${userDetails.email}</p>
                        <p class="text-start"><strong>Subject:</strong> ${userDetails.subject}</p>
                        <p class="text-start"><strong>Message:</strong> ${userDetails.message}</p>
                    </div>`,
                icon: 'info',
                confirmButtonText: 'Close',
                confirmButtonColor: '#3085d6',
                showCloseButton: true,
                customClass: {
                    popup: 'responsive-alert',
                },
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
            });
        } catch (error) {
            console.error('Error fetching user details:', error);
            Swal.fire('Error!', 'Could not fetch user details.', 'error');
        }
    };
    */

    // Show user details from local storage (using local data)
    const showUserDetails = (row) => {
        const userDetails = usersData.find(user => user.email === row.email);

        if (userDetails) {
            Swal.fire({
                title: `<h3 style="color: #2E86C1;">${userDetails.fullName}</h3>`,
                html: `<div class="text-start">
                        <p class="text-start"><strong>Email:</strong> ${userDetails.email}</p>
                        <p class="text-start"><strong>Subject:</strong> ${userDetails.subject}</p>
                        <p class="text-start"><strong>Message:</strong> ${userDetails.message}</p>
                    </div>`,
                icon: 'info',
                confirmButtonText: 'Close',
                confirmButtonColor: '#3085d6',
                showCloseButton: true,
                customClass: {
                    popup: 'responsive-alert',
                },
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
            });
        } else {
            Swal.fire('Error!', 'User details not found in local storage.', 'error');
        }
    };

    // Actions for View
    const actions = [
        {
            label: "View",
            icon: <Eye size={16} />,
            onClick: (row) => showUserDetails(row),
        },
    ];

    return (
        <div>
            <Layout active={'user'} isAdmin={true}>
                <CardSection title={'Users'}>
                    <GenericTable columns={usersColumns} action={true} data={usersData} actions={actions} />
                </CardSection>
            </Layout>
        </div>
    );
};

export default Users;
