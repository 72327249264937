import React from 'react';

const Unauthorized = () => (
  <div style={{ textAlign: 'center', paddingTop: '50px' }}>
    <h1>Unauthorized Access</h1>
    <p>You do not have permission to view this page.</p>
  </div>
);

export default Unauthorized;
