import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import MainContent from '../../components/ghraphs/MainContent';
import CardSection from '../../components/ui/CardSection';
import { GenericTable } from '../../components/ui/table/Table';
import axios from '../../config/server';
import Swal from 'sweetalert2';

const AdminDashboard = () => {
    const [usersDataFromApi, setUsersDataFromApi] = useState([]);
    const [driverData, setDriverData] = useState([]);
    const [drivers, setDrivers] = useState([]);
    const [branches, setBranches] = useState([]);
     // Fetch drivers and branches from backend
     const fetchData = async () => {
        try {
            const driversResponse = await axios.get(`${process.env.REACT_APP_API_URL}/drivers`);
            setDrivers(driversResponse.data); // Fetch drivers
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire('Error', 'Failed to fetch data.', 'error');
        }
    };

    const fetchBranches = async () => {
        try {
            const branchesResponse = await axios.get(`${process.env.REACT_APP_API_URL}/branches`);
            setBranches(branchesResponse.data); // Fetch branches
        } catch (error) {
            console.error('Error fetching data:', error);
            Swal.fire('Error', 'Failed to fetch data.', 'error');
        }
    };

    useEffect(() => {
        fetchData();
        fetchBranches();
    }, []);

    const driverColumns = [
        { header: "Image", accessor: "image", type: "image" },
        { header: "Full Name", accessor: "name", type: "text" },
        { header: "Email", accessor: "email", type: "text" },
        { header: "Active", accessor: "isActive", type: "checkbox" },
        { header: "Branch", accessor: "branch", type: "text" }
    ];

    const driversData = drivers?.map(x=>{
        const logo = x?.profilePic?.data;
        const contentType = x?.profilePic?.contentType;  
        const logoBase64 = logo ? `data:${contentType};base64,${logo}` : null;      
        return {
            id : x?._id,
            name : x?.name,
            email : x?.email,
            image : logoBase64,
            branch : x?.branch?.name,
            branch_id : x?.branch?._id,
            isActive : x?.isActive
        }
    })
    return (
        <div>
            <Layout active={'dashboard'} isAdmin={true}>
                {/* Conditional rendering for MainContent */}
                <div className="mb-4">
                    <MainContent />
                </div>


                {/* Drivers Table */}
                <div className="mb-4">
                    <CardSection title={'Drivers'}>
                        <GenericTable columns={driverColumns} data={driversData} recordsPerPage={5} />
                    </CardSection>
                </div>
            </Layout>
        </div>
    );
};

export default AdminDashboard;

