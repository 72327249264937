import { useState } from "react";
import { useAuth } from "../../../auth/AuthProvider";
import { Form, Button, Container } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const [input, setInput] = useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const auth = useAuth();

  const handleSubmitEvent = (e) => {
    e.preventDefault();
    if (input.username !== "" && input.password !== "") {
      const data = {
        email: input.username,
        password: input.password,
      };
      auth.loginAction(data);
      return;
    }
    alert("Please provide valid input");
  };

  return (
    <Container
    fluid
    className="vh-100 d-flex align-items-center justify-content-center"
    style={{ backgroundColor: "#f8f9fa" }}
  >
    <div
      className="login-container"
      style={{
        backgroundColor: "#f8f9fa",
        width: "90%",
        maxWidth: "1200px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "120px 20px",
      }}
    >
      <h1 style={{ fontSize: "36px", color: "#17294D", fontWeight: "700" }}>
        RZANA
      </h1>
      <Form
        onSubmit={handleSubmitEvent}
        style={{ width: "100%", maxWidth: "300px" }}
      >
      <h2
        className="mb-4 text-center"
        style={{ color: "#17294D", fontWeight: "700", fontSize: "20px" }}
      >
        LOGIN HERE
      </h2>
        <Form.Group controlId="formUsername" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Enter username"
            value={input.username}
            onChange={(e) =>
              setInput({ ...input, username: e.target.value })
            }
            style={{
              borderRadius: "25px",
              borderColor: "#17294D",
              padding: "10px",
              backgroundColor: "#fff",
            }}
            required
          />
        </Form.Group>
  
        <Form.Group controlId="formPassword" className="mb-3 position-relative">
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Enter password"
            value={input.password}
            onChange={(e) =>
              setInput({ ...input, password: e.target.value })
            }
            style={{
              borderRadius: "25px",
              borderColor: "#17294D",
              padding: "10px",
              backgroundColor: "#fff",
            }}
            required
          />
          <div
            className="position-absolute"
            style={{
              top: "50%",
              right: "15px",
              transform: "translateY(-50%)",
              cursor: "pointer",
            }}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? (
              <FaEyeSlash size={20} color="#17294D" />
            ) : (
              <FaEye size={20} color="#17294D" />
            )}
          </div>
        </Form.Group>
  
        <div className="d-flex justify-content-between mb-4">
          <Form.Check
            type="checkbox"
            label="Remember me"
            defaultChecked
            style={{ color: "#17294D", borderRadius: "50%" }}
          />
          <a href="/forgot-password" style={{ fontSize: "14px", color: "#17294D" }}>
            Forgot Password?
          </a>
        </div>
        <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
        <Button
          type="submit"
          style={{
            width: "50%",
            backgroundColor: "#17294D",
            borderColor: "#17294D",
            borderRadius: "25px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          LOGIN
        </Button>
        </div>
      </Form>
    </div>
  </Container>
  
  );
};

export default Login;
