import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import axios from 'axios';
import CardSection from '../ui/CardSection';
import CustomPieChart from './CustomPieChart';
import moment from "moment"
const MainContent = () => {
    const [data, setData] = useState([]); // Raw car data
    const [lineChartData, setLineChartData] = useState([]); // Data for the line chart
    const [pieChartData, setPieChartData] = useState([]); // Data for the pie chart

    // Fetch car data from the backend
    const fetchCars = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/cars`);
            setData(response.data);
        } catch (error) {
            console.error('Error fetching car data:', error);
        }
    };

    // Generate data for the line chart (monthly pickups)
    const processLineChartData = (cars) => {
        const monthlyCounts = {};
        const allMonths = Array.from({ length: 12 }, (_, i) => 
            new Date(new Date().getFullYear(), i).toLocaleString('default', { month: 'short', year: 'numeric' })
        );

        cars.forEach((car) => {
            if (car.pickupTime) {
                const month = new Date(car.pickupTime).toLocaleString('default', { month: 'short', year: 'numeric' });
                monthlyCounts[month] = (monthlyCounts[month] || 0) + 1;
            }
        });

        const chartDataArray = allMonths.map((month) => ({
            name: month, 
            value: monthlyCounts[month] || 0, 
        }));

        setLineChartData(chartDataArray);
    };

    
    const processPieChartData = (cars) => {
        const now = moment(); // Current date and time
        const startOfWeek = now.clone().startOf('week'); // Start of the week (Sunday)
        const endOfWeek = now.clone().endOf('week'); // End of the week (Saturday)
    
        // Filter cars for the current week within the current month and year
        const filteredCars = cars.filter((car) => {
            // Use pickupTime if available, otherwise fall back to dropOffTime for dropped cars
            const timeToCheck = car.status === 'droped' && car.dropOffTime
                ? moment(car.dropOffTime)  // Use dropOffTime for dropped cars
                : moment(car.pickupTime);  // Use pickupTime for other cars
    
            return (
                timeToCheck.isBetween(startOfWeek, endOfWeek, 'day', '[]') && // Include boundaries
                timeToCheck.month() === now.month() &&
                timeToCheck.year() === now.year()
            );
        });
    
        // Calculate status counts
        const pickedCount = filteredCars.filter((car) => car.status === 'picked').length;
        const droppedCount = filteredCars.filter((car) => car.status === 'droped').length;
    
        // Update pie chart data
        setPieChartData([
            { name: 'Picked Cars', value: pickedCount },
            { name: 'Dropped Cars', value: droppedCount },
        ]);
    };
    

    useEffect(() => {
        fetchCars();
    }, []);

    useEffect(() => {
        if (data.length) {
            processLineChartData(data);
            processPieChartData(data);
        }
    }, [data]);

    const now = moment();
    const startOfWeek = now.clone().startOf('week'); // Start of the week (Sunday)
    const endOfWeek = now.clone().endOf('week'); // End of the week (Saturday)
    
    const formattedTitle = `Cars (${startOfWeek.format('MMM D')} - ${endOfWeek.format('MMM D')})`;
    

    return (
        <div className=''>
            <Row>
                <Col lg={8} className="mb-4">
                    <CardSection  style={{color : "#081e3c"}} title={'Monthly Pickup Statistics'}>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={lineChartData}>
                                <CartesianGrid strokeDasharray="3 3" stroke="#0A1E3F" />
                                <XAxis dataKey="name" stroke="#0A1E3F" />
                                <YAxis stroke="#0A1E3F" />
                                <Tooltip
                                    contentStyle={{ backgroundColor: 'white', border: 'none' }}
                                    labelStyle={{ color: '#0A1E3F' }}
                                />
                                <Line type="monotone" dataKey="value" stroke="#0A1E3F" strokeWidth={2} dot={{ fill: 'white' }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardSection>
                </Col>
                <Col lg={4} className='mb-4'>
                    <CardSection title={formattedTitle}>
                        <CustomPieChart pieData={pieChartData} />
                    </CardSection>
                </Col>
            </Row>
        </div>
    );
};

export default MainContent;
