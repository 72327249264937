import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "./AuthProvider";

const PrivateRoute = ({ children, allowedRoles }) => {
  const { user, token } = useAuth();

  // Check if user or token is not available
  if (!token || !user) {
    return <Navigate to="/auth/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(user.role)) {
    return <Navigate to="/unauthorized" />;
  }

  return children ? children : <Outlet />;
};

export const AdminRoute = ({ children }) => (
  <PrivateRoute allowedRoles={['admin']}>{children}</PrivateRoute>
);

export const DriverRoute = ({ children }) => (
  <PrivateRoute allowedRoles={['driver']}>{children}</PrivateRoute>
);

export default PrivateRoute;
